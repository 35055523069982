<template>
  <Wrapper>
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-800 form-filter">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Mã / Tên khoá học"
                        placeholder="Nhập mã hoặc tiêu đề "
                        name="courseName"
                        :value.sync="filter.name"
                      ></basic-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Hiển thị trên App"
                        placeholder="--- Chọn Yes/No ---"
                        name="isApp"
                        :options="[]"
                        :value.sync="filter.isApp"
                        :solid="false"
                        :allowEmpty="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col class="py-0">
                          <label for="sendDate" class="mb-0"
                            >Thời gian hiệu lực</label
                          >
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-date-picker
                            placeholder="Nhập từ ngày gởi"
                            name="startDateTimeToSend"
                          />
                        </b-col>
                        <b-col>
                          <basic-date-picker
                            placeholder="Nhập đến ngày gởi"
                            name="endDateTimeToSend"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Người tạo"
                        placeholder="--- Chọn người tạo ---"
                        name="isApp"
                        :options="[]"
                        :value.sync="filter.createdBy"
                        :solid="false"
                        :allowEmpty="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-check-box
                        :options="[{ text: 'Lọc Inactive', value: false }]"
                        :value.sync="filter.isActive"
                      ></basic-check-box>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Trạng thái"
                        placeholder="--- Chọn trạng thái ---"
                        name="status"
                        :value.sync="filter.status"
                      ></basic-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Hiển thị theo gói dịch vụ"
                        placeholder="--- Chọn gói dịch vụ ---"
                        name="status"
                        :value.sync="filter.package"
                      ></basic-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col class="py-0">
                          <label for="sendDate" class="mb-0">Ngày tạo</label>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-date-picker
                            placeholder="Từ ngày"
                            name="startDateTimeToSend"
                          />
                        </b-col>
                        <b-col>
                          <basic-date-picker
                            placeholder="Đến ngày"
                            name="endDateTimeToSend"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button class="btn btn-success" type="button" @click="createCourse">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm khoá học mới
        </b-button>
      </template>
    </basic-subheader>
    <div class="card card-custom gutter-b">
      <div class="card-body mt-0">
        <template-table
          :column="column"
          :data="data"
          :paging="paging"
          :tableAction="false"
          :selectAction="false"
          :searchAction="false"
          @search="searchRequest"
          @reset="resetRequest"
          @sortBy="sortRequest"
        >
          <template v-slot:body="{ item }">
            <td>
              {{ item.code }}
            </td>
            <td>
              <div class="d-flex">
                {{ item.name }}
              </div>
            </td>
            <td style="width: 20px">
              <action-dropdown
                :value="item"
                :show_copy="false"
                @view="viewItem"
                @edit="createCourse"
                @delete="deleteItem"
              >
              </action-dropdown>
            </td>

            <td>
              <checked :checked="item.isApp" />
            </td>
            <td>
              {{ item.package }}
            </td>
            <td>
              <course-state :status="item.status" />
            </td>
            <td>
              {{ $moment(item.lastUpdated).format('DD/MM/YYYY') }}
            </td>
            <td>
              <div class="d-flex align-items-center mt-5">
                <avatar
                  size="40px"
                  :text="item.updatedBy"
                  :src="item.picture"
                  :rounded="true"
                ></avatar>
                <div class="d-flex flex-column ml-5">
                  <p
                    class="mb-0"
                    style="font-weight: 600; font-size: 13px; color: #515356"
                  >
                    {{ item.updatedBy }}
                  </p>
                </div>
              </div>
            </td>
          </template>
        </template-table>
      </div>
    </div>
    <course-modal />
  </Wrapper>
</template>

<script>
export default {
  name: 'Course',
  components: {
    'course-modal': () => import('./components/CourseModal'),
  },
  data() {
    return {
      filter: {},
      openModal: false,
      sort: {
        by: null,
        order: null,
      },
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'code',
          label: 'Mã ',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Tên khoá học',
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          sortable: false,
        },
        {
          key: 'isApp',
          label: 'Hiển thị App',
          sortable: false,
        },
        {
          key: 'package',
          label: 'Gói dịch vụ',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
        },
        {
          key: 'lastUpdate',
          label: 'Cập nhật lần cuối',
          sortable: false,
        },
        {
          key: 'updatedBy',
          label: 'Người cập nhật',
          sortable: false,
        },
      ],
      data: [],
    };
  },
  created() {
    // this.loadData();
  },
  methods: {
    editItem() {},
    viewItem() {
      this.$router.push({ path: 'course/1' });
    },
    deleteItem() {},
    resetRequest() {},
    createCourse() {
      this.openModal = true;
      this.$bvModal.show('course-modal');
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    searchRequest() {
      this.loadData();
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('course')
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.mw-400 {
  min-width: 400px;
}
.mw-800 {
  min-width: 800px;
}
</style>
